import {CompactButton} from "../components/button";
import React, {useEffect} from "react";
import {Order} from "../models/order";
import {Field, TextField} from "../components/field";

const ManageOrders = () => {
    // const {state: authState, logout, graphqlClient, client} = useAuth();
    const [orders, setOrders] = React.useState<Order[]>([]);
    const [page, setPage] = React.useState(0);
    const [reachEnd, setReachEnd] = React.useState(false);

    useEffect(() => {
        if (!reachEnd) {
            // client.get(
            //     `/order/manage_orders?page=${page}`
            // ).then((res) => {
            //     if (!res.data.length) {
            //         setReachEnd(true);
            //     }
            //     setOrders([...orders, ...res.data.map((d) => Order.fromREST(d)).filter((e) => e.status !== "待付款")])
            // });
        }
    }, [page]);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
        }}>
            {
                orders.map((d) =>
                    <div
                        key={`order-${d.id}`}
                        style={{
                            marginBottom: 24,
                            border: '1px solid #eee',
                            borderRadius: 4,
                            padding: '8px 16px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                        }}>
                        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                            <div style={{}}>
                                {`${d.date?.getFullYear()}年 ${(d.date?.getMonth() ?? 0) + 1}月 ${d.date?.getDate()}日`}
                            </div>
                            {
                                d.status && <div>{d.status}</div>
                            }
                        </div>
                        <div style={{
                            width: '100%',
                        }}>
                            {
                                (d.items ?? []).map((d) => <ProductItem item={d}/>)
                            }
                        </div>
                        <hr
                            style={{
                                height: 1,
                                background: '#f1f2f6',
                                width: '100%',
                            }}
                        />
                        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                            {
                                d.logisticInfo && (
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        flexGrow: 1,
                                        alignItems: 'start'
                                    }}>
                                        <div>
                                            {`收件人名稱 : ${d.logisticInfo['name']}`}
                                        </div>
                                        <div>
                                            {`收件人電話 : ${d.logisticInfo['phone']}`}
                                        </div>
                                        <div>
                                            {`收貨方式: ${d.logisticInfo['storeName'] ? (
                                                d.logisticInfo['collection'] ? '超商取貨' : '超商取貨付款'
                                            ) : '宅配'}`}
                                        </div>
                                        {
                                            d.logisticInfo['address'] && <div style={{textAlign: 'start'}}>
                                                {`地址 : ${d.logisticInfo['address']}`}
                                            </div>
                                        }
                                        {
                                            d.logisticInfo['storeName'] && <div style={{textAlign: 'start'}}>
                                                {`取貨門市 : ${d.logisticInfo['storeName']}`}
                                            </div>
                                        }
                                        <div style={{ height: 16}} />
                                        {
                                            d.logisticInfo['storeName'] ? (
                                                <div style={{ display: 'flex'}}>
                                                    <CompactButton
                                                        onClick={() => {
                                                            // client.post('/order/gen_logistics_id', {
                                                            //     orderId: d.id,
                                                            //     c2c: true,
                                                            // });
                                                        }}
                                                    >
                                                        產生超商寄件單
                                                    </CompactButton>
                                                    <div style={{ width: 16}} />
                                                    <CompactButton
                                                        onClick={() => {
                                                            // client.post('/order/gen_logistics_id', {
                                                            //     orderId: d.id,
                                                            // });
                                                        }}
                                                    >
                                                        產生物流中心託運單
                                                    </CompactButton>
                                                </div>
                                            ) : (
                                                <CompactButton
                                                    onClick={() => {
                                                        // client.post('/order/gen_logistics_id', {
                                                        //     orderId: d.id,
                                                        // });
                                                    }}
                                                >
                                                    產生託運單
                                                </CompactButton>
                                            )
                                        }
                                    </div>
                                )
                            }
                            <div/>
                            <div style={{textAlign: 'right', margin: '4px 0px'}}>
                                {`總價 : $ ${d.totalPrice}`}
                            </div>
                        </div>
                    </div>
                )
            }
            {
                !reachEnd && <CompactButton
                    onClick={() => {
                        setPage(page + 1);
                    }}
                >
                    載入更多
                </CompactButton>
            }
        </div>
    );
}

const ProductItem = ({item}) => <div style={{
    margin: '8px 0px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
}}>

    <img src={item.product.coverUrl} style={{height: 60, width: 80, objectFit: 'cover', borderRadius: 4}}/>
    <div style={{width: 24}}></div>
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'start'}}>
        <div>
            {
                item.product.name
            }
        </div>
        <div style={{fontSize: 12, color: '#999'}}>
            {
                `${item.product.specName} x ${item.count}`
            }
        </div>
    </div>
    <div style={{flexGrow: 1, textAlign: 'right'}}>
        {`$ ${item.product.price * item.count}`}
    </div>
</div>

const MyOrders = () => {
    // const {state: authState, logout, graphqlClient, client} = useAuth();
    const [orders, setOrders] = React.useState<Order[]>([]);

    useEffect(() => {
        // client.get(
        //     '/order/my_orders'
        // ).then((res) => {
        //     setOrders(res.data.map((d) => Order.fromREST(d)).filter((e) => e.status !== "待付款"))
        // });
    }, [])

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
        }}>
            {
                orders.length === 0 && <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexGrow: 1,
                }}>
                    <div style={{fontSize: 24, color: '#999'}}>
                        您還沒有訂單紀錄
                    </div>
                </div>
            }
            {
                orders.map((d) =>
                    <div
                        key={`order-${d.id}`}
                        style={{
                            marginBottom: 24,
                            border: '1px solid #eee',
                            borderRadius: 4,
                            padding: '8px 16px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                        }}>
                        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                            <div style={{}}>
                                {`${d.date?.getFullYear()}年 ${(d.date?.getMonth() ?? 0) + 1}月 ${d.date?.getDate()}日`}
                            </div>
                            {
                                d.status && <div>{d.status}</div>
                            }
                        </div>
                        <div style={{
                            width: '100%',
                        }}>
                            {
                                d.items.map((d) => <ProductItem item={d}/>)
                            }
                        </div>
                        <hr
                            style={{
                                height: 1,
                                background: '#f1f2f6',
                                width: '100%',
                            }}
                        />
                        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                            {
                                d.logisticInfo && (
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        flexGrow: 1,
                                        alignItems: 'start'
                                    }}>
                                        <div>
                                            {`收件人名稱 : ${d.logisticInfo['name']}`}
                                        </div>
                                        <div>
                                            {`收件人電話 : ${d.logisticInfo['phone']}`}
                                        </div>
                                        {
                                            d.logisticInfo['address'] && <div style={{textAlign: 'start'}}>
                                                {`地址 : ${d.logisticInfo['address']}`}
                                            </div>
                                        }
                                        {
                                            d.logisticInfo['storeName'] && <div style={{textAlign: 'start'}}>
                                                {`取貨超商 : ${d.logisticInfo['storeName']}`}
                                            </div>
                                        }
                                        {
                                            d.logisticInfo['collection'] && <div>
                                                取貨付款
                                            </div>
                                        }
                                    </div>
                                )
                            }
                            <div/>
                            <div style={{textAlign: 'right', margin: '4px 0px'}}>
                                {`總價 : $ ${d.totalPrice}`}
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
};

const EditProfile = () => {
    // const {state: authState, updateProfile} = useAuth();
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [address, setAddress] = React.useState('');

    useEffect(() => {
        // const user = authState?.user;
        // if (user?.name) setName(user.name);
        // if (user?.email) setEmail(user.email);
        // if (user?.phone) setPhone(user.phone);
        // if (user?.address) setAddress(user.address);
    }, [])
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
        }}>
            <Field label="姓名">
                <TextField
                    value={name}
                    onChange={(value) => {
                        setName(value);
                    }}
                />
            </Field>
            <div style={{height: 8}}></div>
            <Field label="Email">
                <TextField
                    readOnly={true}
                    value={email}
                    onChange={(value) => {
                        setEmail(value);
                    }}
                />
            </Field>
            <div style={{height: 8}}></div>
            <Field label="電話">
                <TextField
                    value={phone}
                    onChange={(value) => {
                        setPhone(value);
                    }}
                />
            </Field>
            <div style={{height: 8}}></div>
            <Field label="地址">
                <TextField
                    value={address}
                    onChange={(value) => {
                        setAddress(value);
                    }}
                />
            </Field>
            <div style={{height: 24}}></div>
            <div style={{
                display: 'flex',
                justifyContent: 'end'
            }}>
                <CompactButton onClick={() => {

                }}>
                    變更密碼
                </CompactButton>
                <div style={{width: 24}}></div>
                <CompactButton onClick={async () => {
                    // await updateProfile({
                    //     username: name,
                    //     email,
                    //     phone,
                    //     address,
                    // });
                }}>
                    儲存
                </CompactButton>

            </div>
        </div>
    )
};


const Profile = () => {
    // const {state: authState, logout} = useAuth();

    const [active, setActive] = React.useState(0);


    // useEffect(() => {
    //     if (R.isNil(authState?.token)) {
    //         navigate('/login');
    //     }
    // }, [authState]);

    const TabOption = (props) => (
        <div style={{
            fontWeight: props.active ? 'bold' : 'normal',
            borderBottom: props.active ? '2px solid #000' : '1px solid #F1F2F6',
            margin: 16,
            padding: 8,
            cursor: 'pointer'
        }}
             onClick={props.onClick}
        >
            {
                props.children
            }
        </div>
    );


    return (
        <div>
            <div style={{textAlign: 'center'}}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: 800,
                    margin: '0 auto',
                }}>
                    <div style={{
                        display: 'flex',
                        width: '100%',
                    }}>
                        <div style={{
                            flexDirection: 'column'
                        }}>
                            {
                                // authState?.user?.role?.name === 'admin' && (
                                //     <TabOption active={active == 2}
                                //                onClick={() => {
                                //                    setActive(2);
                                //                }}
                                //     >
                                //         訂單管理
                                //     </TabOption>
                                // )
                            }
                            <TabOption active={active == 0
                            }
                                       onClick={() => {
                                           setActive(0);
                                       }}
                            >
                                訂單紀錄
                            </TabOption>
                            <TabOption active={active == 1}
                                       onClick={() => {
                                           setActive(1);
                                       }}
                            >
                                編輯個人檔案
                            </TabOption>
                            <TabOption active={false}
                                       onClick={() => {
                                           // logout();
                                       }}
                            >
                                登出
                            </TabOption>
                        </div>
                        <div style={{
                            flexGrow: 1,
                            padding: '24px 24px'
                        }}>
                            {
                                active == 0 && <MyOrders/>
                            }
                            {
                                active == 1 && <EditProfile/>
                            }
                            {
                                active == 2 && <ManageOrders/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Profile;
