import React from "react";
import './field.css';

export const Field = ({label, children}) => {
    return (
        <div
            style={{
                padding: '8px 0px',
                display: "flex",
                justifyContent: 'space-between',
                width: "100%",
            }}
        >
            <div style={{ color: '#666', minWidth: 48}}>
                {label}
            </div>
            <div style={{ width: 24}}></div>
            <div style={{flexGrow: 1}}>
                {children}
            </div>
        </div>
    );
}

export const TextField = ({value, onChange, readOnly = false}) => {
    return (
        <input
            readOnly={readOnly}
            className="form-text-field"
            value={value} onChange={(e) => {
            onChange(e.target.value);
        }}/>
    );
}


